@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
body{background:rgba(46, 53, 96, 1) !important; box-sizing: border-box; text-decoration: none;font-family: 'Source Sans Pro', sans-serif !important;}
a{text-decoration: none !important;}
*{margin: 0; padding:0;}
p{margin-bottom: 0;}
.tab-row a {
  padding: 0 0 10px 0;
  color: #747AA8;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  margin-right: 10%;
  letter-spacing: 1px;
}
.maine-content {margin-top: 20px;}
.tab-row { display: flex;  width: 100%;  margin:40px 0 20px;}
.user-name-row img {width: 30px; height: 30px; border-radius:10px;margin-right: 10px;}
.row-box { display: flex;  margin-top:0;  width: 100%; align-items: center;}
.row-box p {margin-bottom: 0;}
.user-name-row { display: flex;}
.button-row button {background: #35c8c2;border: 0;}
.tab-col  {padding: 20px;  margin-bottom: 30px;}
.button-row { border-top: 1px solid #58619d ;  margin: 15px -20px 0;   padding: 20px 20px 0;}
.text-heading {font-size: 12px; color: #747AA8;  font-weight: 500; margin-right: 15px;}
.amount {font-size: 12px; font-weight: 500; color: #fff; font-weight: 700;}
.font-18{font-size: 12px; font-weight: 500; color: #fff;font-weight: 700;}
.user-name-row p {color: #fff;font-size: 15px; margin-bottom: 0;}
.user-name-row .small-text {font-size: 13px; font-weight: 400; color: #747AA8; line-height: 8px;}
.user-name-row p span {color: #b5b5b5;}
.user-name-row p {font-weight:700;}
.Logo img {height:45px; }
.Logo {display: flex;align-items: center; margin-bottom:62px;}
.Logo p {margin: 14px 0 0 15px;color: #fff;letter-spacing: 2px;font-size: 14px;font-weight: 500;}


.btn {height: 45px; color: #fff; letter-spacing: 1px; border: 1px solid #809AFD; padding:5px 15px; font-size:12px;}
.btn-main {
  height: 35px;
  color: #fff;
  letter-spacing: 1px;
  border: 1px solid #809AFD;
  padding: 5px 30px;
  font-size: 12px;
  background: transparent;
  border-radius: 15px;
}

.finished-row { background: #f2f2f2; border-radius:15px; padding: 10px;  display: flex; align-items: center; margin:15px 0 0;}
.finished-row .icon {
    background: #F3BA2F;
    padding: 4px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.finished-row.finished p { margin: 0 0 0; color: #fff; font-size:12px;;}
.finished-row .icon img { width: 80%;}
.finished-row button {background: #35c8c2; border: 0; outline: none;}
.finished-row.finished  button{background: #fff; color:#000;}
.finished-row button span { width: 8px;  height: 8px;  border-radius: 100%; background: #000; display: inline-block;}
.finished-amt {display: flex; align-items: center;  justify-content: space-between; margin-top:5px;}
.finished-amt p { font-size: 12px; font-weight: 500;}
.finished-amt p.amt{color:#b5b5b5 }
.tab-row a.active {color: #ffffff;border-bottom: 2px solid #7C6ADD; font-weight: 700;}
.finished-row.finished {background: transparent;border: 1px solid #404881;  padding: 12px 15px; margin-top: 0;}
.fin-right {display: flex;font-size: 10px; align-items: center; margin-left: 10px;}
.fin-right span { color: #809AFD; margin-left: 5px;}
.blue-bg{background:#627EEA !important;}

.deal-row {align-items:center;margin-bottom: 40px;display: flex;justify-content: space-between;}
.font-18{color:#b5b5b5; font-size:18px; margin-right:10px;}
.mt-0{margin-top:0 !important;}
.total-box { display: flex;  align-items: center;}
.progress {display: flex;height:4px !important; }
.progress-bar {background: linear-gradient(103.59deg, #7C6BDE 0.13%, #A79AF6 59.74%);}
.deal-info ul li {
  list-style: none;
  margin-top: 10px;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: right;
  letter-spacing: 0.2px;
  font-weight:700;
}

.tire-section-heading {margin-bottom: 15px;}

.col-100{margin-bottom:20px;}
.descr-btn a { color: #35c8c2;  margin-top: 20px;  display: inline-block;}
.dl-dt { margin-top: 10px !important;}
.heading-sm {margin-bottom: 15px; font-weight: 500;}

.modal-content{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
}
.login-box {background: linear-gradient(111.02deg, #30386D 7.43%, #3B427A 97.77%); border-radius:20px; max-width:500px; width:100%;position: relative;}
.login-box .content{padding:40px 90px; }
.login {width: 100%;min-height: 100vh;display: flex;align-items: center;justify-content: center;}
.login-box button {width: 100%;}
.nt-member { font-size: 12px;  margin-top: 5px; color: #747AA8;}
.nt-member a {color: #fff;}
.login-box h1 {font-size:30px; color: #fff;  margin-bottom: 25px; font-weight:700; display: flex; justify-content: center;}
.input-design {background-color: #2e3560 !important;outline: none;border-color: #3d446e !important;height: 45px;border-radius: 5px !important; outline: none !important;}
.login-box h1 svg {margin-right: 10px;}
.login button {background: transparent !important; border: 2px solid #464e82; border-radius: 15px; outline: none; margin-top:25px;}
.close-btn {position: absolute;right: 40px; top: 20px; cursor: pointer;}

.login label { display: inline-block; color: #747AA8; font-size: 12px;}

.login .form-label { color: #fff; font-size: 12px;}

.user-img img {width: 40px;height: 40px; border-radius: 100%; margin-right:10px;}
.user-img {display: flex; align-items: center; margin-top: 30px;}
.submit-btn  {display: flex;justify-content: flex-end; align-items: center;}
form.deal-form { margin:30px 0 20px; border-radius: 10px;  }
form.deal-form  .form-control { background-color: #2e3560 !important;  outline: none; border-color: #4f5680 !important; height: 40px;}
  .page-heading{font-size: 34px; color: #040404;  margin-bottom: 20px;}

.side-bar {width: 350px;}
.box-container {padding-top:30px;padding-bottom:30px;}
.right-content {width: calc(100% - 600px); padding: 10px 30px;}
.left-nav {
  width: 250px;
  background: #292f56;
  padding: 25px 40px;
  border-radius:20px;
}
ul#menu > li svg {margin-right: 18px;}
ul#menu > li:nth-child(3) { margin-bottom: 0;}
.left-nav ul {padding: 0;}
.left-nav ul li a {padding:0; width: 100%;  color: #747AA8; display: inline-block; text-decoration: none; font-size: 13px; letter-spacing:1px;font-weight:700;}
.left-nav label { color: #fff;}
.bar-heading img {border-radius: 10px;}
ul#menu > li{float:left; list-style-type:none; position:relative; width: 100%; margin-bottom:40px;} 
ul#menu label{ position:relative;  display:block;  cursor:pointer;font-size: 13px; color: #747AA8;  font-weight:700; letter-spacing:1px;} 
ul#menu label .fa{margin-right:5px;}
ul#menu label:after{
    content:"";
    position:absolute;
    display:block;
    top:12px;
    right:5px;
    width:0;
    height:0;
    border-top:4px solid rgba(255,255,255,.5);
    border-bottom:0 solid rgba(255,255,255,.5);
    border-left:4px solid transparent;
    border-right:4px solid transparent;
    transition:border-bottom .1s, border-top .1s .1s;
    }
  
    ul#menu  label:hover,
    ul#menu input:checked ~ label{}  
    ul#menu  input:checked ~ label:after{
    border-top:0 solid rgba(255,255,255,.5);
    border-bottom:4px solid rgba(255,255,255,.5);
    transition:border-top .1s, border-bottom .1s .1s;
  }

  ul#menu > li.active label, ul#menu > li.active a {
    color: #fff;
  }

  ul#menu  input{display:none}
  /*show the second levele menu of the selected voice*/
  ul#menu input:checked ~ ul.submenu {padding-left: 15px; max-height: 300px; transition: max-height 0.5s ease-in;border-left: 1px solid #30386d;;margin-bottom: 20px;}
  
  /*style for the second level menu*/
ul.submenu {margin-top:40px; max-height: 0;   overflow: hidden; list-style-type: none; box-shadow: 0 0 1px rgb(0 0 0 / 30%);transition: max-height 0.5s ease-out;min-width: 100%;}
ul.submenu li a {
  display: block;
  padding: 12px 0 15px 15px;
  color: #747AA8;
  text-decoration: none;
  transition: background .3s;
  white-space: nowrap;
  border-radius: 10px; letter-spacing:1px; font-weight:60;
}

ul.submenu li a.active {background: #2e3560;}

.bar-heading {display: flex; justify-content:space-between; align-items: center; flex-wrap: wrap;}
.bar-heading h3 {font-size:13px;color: #747AA8; font-weight:700;}
.bar-box {background:#292f56;padding:20px;border-radius: 20px; margin-bottom:20px; position: relative;}
.bar-box:last-child {margin-bottom:0;}
.content-box {background: #30386D; padding: 20px; border-radius: 20px;}
.right-content h1 { color: #fff; font-size:18px; margin-bottom: 0; font-weight:500; }
.heading-row {display: flex; align-items: center;}
.bc-btn {width: 25px; height: 25px; display:flex; background: #00000024; border-radius: 100%;color: #fff;align-items: center;margin-right: 10px; justify-content: center;}
.chart-box {border: 1px solid #404881;border-radius:10px;padding: 20px;text-align: center;}
.chart-box-content p { font-size: 12px; color: #747AA8;margin-bottom: 0;}
p.price-chart {font-size:16px; font-weight: 700; color: #fff;}
p.max-allocation {font-size:14px; font-weight: 400; color: #fff;}
.max-allocation .max-allocation-amount {font-size:22px; font-weight: 700; color: #747AA8;}
.contibution-text{font-size: 14px; font-weight: 600; color: #fff; margin-left: 2%;}
.chart-box-content {margin-top: 10px;}
p.pr-percent { color: #fff;  font-size: 13px;  margin-bottom: 5px;}
.chart-row { padding: 25px 0 20px;}
.tire-section-heading span {
    background: #fff;
    padding:4px 10px;
    display: inline-block;
    border-radius: 0 0 10px 10px;
    font-size: 12px;
    font-weight: 700;
    color: #2A3058;
}

.line-chart {position: relative; margin: 0 -20px -20px;}

.deal-info ul {padding-left: 0;}
.minus-plus-row {
  display: flex;
  align-items: center;
  width: 100%;
  background: #292f56;
  padding: 0 10px;
  border-radius: 15px;
  justify-content: space-between;
  height: 35px;
}

.right-str {display: flex; margin-bottom:10px;}
.right-str .text-heading {margin-right: 25px;}

.minus-plus-row button { background: transparent; outline: none;  border: 0;  color: #747AA8;  font-size: 20px;}
.minus-plus-row p { margin-bottom: 0;  color: #fff;  font-size: 13px;   font-weight: 700;}
.minus-plus-row .badge { background: #747AA8;  font-size: 10px; margin-right:5px;}
.plus-btn {display: flex; align-items: center;}
.cnt-button {
   height:35px; font-weight:700; font-size: 12px !important;margin-bottom: 10px;
   background: transparent !important;width: 100%;
   border: 0 !important; position: relative;
  
  }


  .cnt-button:before{

    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:15px; 
    padding:2px; 
    background:linear-gradient(103deg, #7F67DB,#607DEA); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
  }

.cnt-button.cancel-btn:before
{ content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius:15px; 
  padding:2px; 
  background:linear-gradient(99deg, #D66C4A,#EF8A69); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude; 
}
  
.cnt-button:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; border-radius:15px;}
.cnt-button.cancel-btn:hover { background:linear-gradient(99deg, #D66C4A,#EF8A69) !important;  border-radius:15px;}





button.cnt-button.cancel-btn { border-color: #df8223 !important;}
.qust {text-align: center;font-size: 12px; color: #fff; position: relative; margin:10px 0 0  ;}
.qust .badge {
  background: #fff;
  color: #282e53;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  margin: 0 0 0 5px;
  position: relative;
  top: -5px;
}
.tire-section {border-top: 1px solid #404881; margin-left: -20px; margin-right: -20px;padding: 0 5px; border-bottom: 1px solid #404881;margin-bottom: 20px;}
.custom_radio input[type="radio"]{display: none;  }
.tire-section .cnt-button:first-child {margin-bottom: 25px;}
  .custom_radio input[type="radio"] + label{
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    margin-right: 2em;
    cursor: pointer;
    line-height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .custom_radio input[type="radio"] + label:before,
  .custom_radio input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }

  .number-cnt {position: absolute; display: flex; bottom: 25px;  width: 100%;}
  .numb-p {flex: 1;  text-align: center;  font-size: 12px;   color: #fff;}
  .select-point {
    width: 7px;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 5px;
    top: 64px;
}

  .custom_radio input[type="radio"] + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em  #282e53, inset 0 0 0 1em #282e53;
  }
  .custom_radio input[type="radio"] + label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em #282e53, inset 0 0 0 1em #c6c6c6;
  }

  .custom_radio input[type="radio"]:checked + label:before {
      transition: all .3s ease;
      box-shadow: inset 0 0 0 0.2em #282e53, inset 0 0 0 1em #4753a3;
  }
  .custom_radio {
    display: flex;
    align-items: center;
}
.buy-section button { margin: 0;}
.buy-section {  font-size: 12px;  color: #fff;   padding: 20px;}
.descr-btn a { color:#747AA8;  font-size: 12px;}

.bar-cnt {text-align: center;  margin: 20px 0 10px;}
.bar-cnt h3 {font-size: 50px; font-weight: 300; color: #fff; margin: 0;}
.bar-cnt p { color: #747AA8;  font-size: 12px;  margin-bottom: 0;}
.tm {margin-right: 20px;}
.tm .fa {color: #7C6BDE; font-size: 8px;}
.nc .fa {color:#617EEB; font-size: 8px;}
.amount .fa { color: #52B5BC;  font-size: 8px;}

.all-deal-box .user-name-row.dl-dt {margin-top: 0 !important;}
.all-deal-box .user-name-row img {height: 40px;width: 40px;object-fit: cover;border-radius: 15px;}
.all-deal-box .user-name-row p {margin-bottom: 5px;}
.all-deal-box {background: #30386D; padding: 20px; border-radius: 20px; box-shadow: 0px 4px 100px rgba(33, 39, 76, 0.7); margin-bottom:40px;}
.all-deal-box .chart-box {display: flex;justify-content: space-around;padding: 10px 10px;align-items: center;}
.all-deal-box .chart-box .chart-box-content {margin-top: 0;}
.all-deal-box  p.price-chart .fa {color: #8169DD; font-size: 8px;}
.all-deal-box .chart-box .progress-bar-row {width:50%;}
.header-chart {margin-bottom: 20px;}
.btn-main:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%);}
.yellow-text{color: #EBB324 !important;}
.all-deal-box .user-name-row .pr-percent {font-size: 15px;font-weight: 700;}
.overlay-popup {width: 100%;height: 100%;position: fixed;top: 0; left: 0;  display: flex;  align-items: center;  justify-content: center; background: #0000005c; z-index: 9;}
.popup-box { max-width: 500px; width: 100%;  background: linear-gradient(111.02deg, #30386D 7.43%, #3B427A 97.77%); border-radius: 20px; position: relative;}
.popup-body {padding: 30px 80px 0;}
.popup-header h2 {font-weight: 500;color: #fff;text-align: center;font-size: 30px;margin-bottom: 30px;}
.popup-header p {color: #747AA8;font-size: 12px;text-align: center;}
.popup-body .form-label {color: #fff;font-size: 12px; letter-spacing: 1px;}

.popup-box button {width: 100%;font-size: 12px;background: transparent !important;border-radius: 15px; margin-top:10px;}
.footer-popup {border-top: 1px solid #404881; padding: 10px 20px 20px;}
.footer-popup p {margin-bottom: 0; font-size: 12px;  color: #747AA8;  text-align: center;}
.closed {color: #fff;position: absolute;right: 26px;top: 10px; font-size: 24px; cursor: pointer;}
.form-control { background-color: #2e3560 !important;outline: none;border-color: #3d446e !important;height: 45px;border-radius: 5px !important; outline: none !important;}
.deal-form .form-label {font-size: 12px; color: #fff;  letter-spacing: 1px;}

.no-access-popup{padding: 30px 30px;}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1440px !important;}
}

/* Tablet view anf mobile view code */

.orange-button { height:35px;  font-size: 13px !important; background: transparent !important;
  border: 0 !important; position: relative;      padding: 5px 30px; letter-spacing: 1px;
 } 

 .orange-button:before{
    content:"";
   position:absolute;
   top:0;
   left:0;
   right:0;
   bottom:0;
   border-radius:15px; 
   padding:2px; 
   background:linear-gradient(98.6deg, #EEAD31 0%, #FFD482 94.77%);
   -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
   -webkit-mask-composite: destination-out; 
   mask-composite: exclude; 
 }


.red-button { height:35px;  font-size: 13px !important; background: transparent !important;
  border: 0 !important; position: relative;       padding: 5px 30px;letter-spacing: 1px;
 } 

 .red-button:before{
    content:"";
   position:absolute;
   top:0;
   left:0;
   right:0;
   bottom:0;
   border-radius:15px; 
   padding:2px; 
   background:linear-gradient(99.05deg, #D66C4A 0%, #C76343 88.57%);    
   -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
   -webkit-mask-composite: destination-out; 
   mask-composite: exclude; 
 }

 .buy-btn-row button:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; border-radius:15px;}

 
.gr-button{color:#fff;border:0; background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; 
  height: 35px;
  letter-spacing: 1px;
  border: 1px solid #809AFD;
  padding: 5px 30px;
  font-size: 13px;
  border-radius: 15px;
}

.menu-mb .dropdown { display: none;}
.h4-hd {color: #fff;font-size: 12px;font-weight: 700;margin: 0 0 15px;}
.hd-down {background: transparent !important;border: 0 !important;outline: none; display: none;}
.hd-down button{padding: 5px 10px; font-size: 9px;}
.mobile-slider{display:none;}
.show-mobile {display: none;}

.progress-div-data{
  width: 100%; position: absolute; top: 70%; left: 0; margin-top: -20px; line-height:12px; text-align: center; z-index: 999999999999999; 
  color: #fff; font-size: 12px;
}

#social-link-menu{
  background: #30386d;
}
#social-link-menu ul{ 
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#social-link-menu li{
  line-height: 15px;
}

@media screen and (max-width:8000px)  
{
  .btn-rw-desktop{display: flex;}
  .btn-rw-mobile { display: none; }
}

@media screen and (max-width:1199px)  
{
.left-nav {position: absolute;width: 100% !important;left: 0;right: 0; padding: 10px 15px;border-radius: 0;height: 65px;}
.Logo{margin:0 auto; width:120px;} 
.right-content.col-md-6 {width: 100%; padding: 0; margin-top:80px;} 
.side-bar.col-md-3 {width:100%;margin-top: 30px;display: flex;justify-content: space-between;flex-wrap: wrap;}
#menu{display: none;}
.box-container {padding-top: 0;}
.cnt-lft {max-width:960px;margin: auto;display: flex;}
.menu-mb #menu {display: block;}
.menu-mb .dropdown-toggle {white-space: nowrap;background: transparent;border: 0; outline: none;}
.menu-mb .dropdown { display: block;}
.left-nav .btn-success:hover { color: #fff;  baground-color: transparent; border-color: transparent; box-shadow: none !important;}
.show>.btn-success.dropdown-toggle{background: transparent !important; border: 0 !important; outline: none !important; box-shadow: none !important;}
.menu-mb .dropdown-menu.show {background: #30386d; width: 300px; padding: 20px;}
.menu-mb .btn-success:hover { background-color: transparent !important; border-color:transparent !important; box-shadow: none !important;}
.menu-mb button:after {display: none;}
.tb-none-mb-s{display:none;}
.bar-box {width: 48%;}
.tab-right{margin-left: 10%;}
.DesktopViewOnly{display: none;}
.btn-rw-mobile { display: flex; }
.btn-rw-desktop{display: none;}
.show-mobile {display: none;}
}


@media screen and (max-width: 767px)  
{
.box-container{max-width:100% !important;}
.none-mb{display: none !important;}
.tb-none-mb-s{display: block;}
.right-str {width: 100%; justify-content: space-between;}
.user-name-row.dl-dt { margin: 0 0 20px;}
.chart-box {margin-bottom: 20px;}
.tire-section.row .col-md-4 {margin-bottom: 20px;}  
.tab-col { padding: 0;}
.h4-hd {margin: 10px 0 20px;}
.custom_radio { width: 100%;justify-content: space-between; margin-bottom: 30px;}
.tire-section.buy-section.row button.cnt-button.btn.btn-primary {margin: 0;}
.bar-box {width: 100%;}
.hd-down {display: block;}
.DesktopViewOnly{display: none;}
.mobile-slider{display:block !important;}
.mobile-slider .owl-dots {display: none;}
.show-mobile { width: 100%;display: block;}
.btn-rw-mobile { display: none;}
.btn-rw-desktop{display: none;}
.full-width-mobile { width: 100%;}
.token-content.uc-content {margin: 30px 0 0;}
/* Siderbar */
.progress-div-data{
  width: 100%; position: absolute; top: 55%; left: 0; margin-top: -20px; line-height:12px; text-align: center; z-index: 999999999999999; 
  color: #fff; font-size: 12px;
}
.tab-right{margin-left: 0%;}
#social-link-menu{width: 100%; left:0; border-bottom-right-radius: 12px;border-bottom-left-radius: 12px}
}

@media screen and (max-width: 375px)  {
.footer-popup p { margin-bottom: 0; font-size: 10px; color: #747AA8; text-align: center;}
.amount-waddres { font-size: 12px;}
/* Siderbar */
.progress-div-data{
  width: 100%; position: absolute; top: 55%; left: 0; margin-top: -20px; line-height:12px; text-align: center; z-index: 999999999999999; 
  color: #fff; font-size: 12px;
}

.tab-right{margin-left: 0%;}
}

.amount-waddres { font-weight: 500; color: #fff; font-weight: 700; }

.token-content h3 {color: #fff;font-size: 15px;font-weight: 700; margin: 0 0 15px;}
.token-content{margin: 0 0 25px;background: linear-gradient(111.68deg, #30386D 7.35%, #3B427A 96.83%); box-shadow: 0px 4px 100px rgb(33 39 76 / 70%);border-radius: 20px; padding: 20px;}
.token-content button {width: 100%;margin: 15px 0 0;}
.token-content.uc-content .hd {width: 100%;margin: 0 0 10px;color: #fff; font-size: 12px; text-align: center;}
.token-content.uc-content {margin: 0;background: transparent; box-shadow: none; padding: 0;}

p.amt-cnt {font-size: 12px; color: #fff; letter-spacing: 1px; margin: 0 0 5px;}
.amout {display: flex;align-items: center;justify-content: space-between;color: #fff;font-size: 12px;background: #282e55;border-radius: 5px;padding: 10px 10px;}
.amout p{margin:0;}
.uc-content .amout span {background: white;color: #303767;border-radius: 3px;padding: 0 5px;font-weight: 700;}
.date-tk { display: flex;  align-items: center;}
.date-tk p span{font-size: 20px; font-weight: 700;color: #809AFD; margin: 0 ; display: inline-block;text-align: center;width: 100%;}
.date-tk p  .sm-text{width: 100%;display: inline-block;font-size: 12px;font-weight: 300;color: #747AA8;text-align: center; }
.date-tk p {line-height: 1; margin:0 0 0;}
.date-tk {display: flex; align-items: baseline;}

.date-row {display: flex;align-items: center; justify-content: space-between; margin:0 0 0; min-height:40px;}
.date-row h3 {margin: 0;}
.uc-content .date-row {border: 1px solid #404881;border-radius: 10px;padding: 10px; flex-wrap: wrap; justify-content: center; margin:0 0 15px;}
.c-dots {font-size: 20px;font-weight: 700;color: #809AFD;display: flex;}

/* My code */

/* HIde the input arrows */
/* Chrome, Safari, Edge, Opera */
#contributionAmount::-webkit-outer-spin-button,
#contributionAmount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#contributionAmount[type=number] {
  -moz-appearance: textfield;
}

#contributionAmount{background-color: #282e53; border-style: hidden;text-align: center;font-size: 13px; font-weight: 700;color: #fff; width: 65%;}
#contributionAmount:focus{outline: none;}
#contributionAmount::-webkit-inner-spin-button, 
#contributionAmount::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.overlay-box {position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); z-index: 1000; }
.parentDisable { position: fixed; top: 0; left: 0; opacity: 0.8; z-index: 998; height: 100%; width: 100%; pointer-events: none; }
.hide{ display: none;}
/* deal */
.deal-info .heading-sm {margin-bottom: 15px;font-size: 16px; font-weight: 500;color: white;}
.bottom-content { color: #747AA8;  font-size: 12px;  margin-bottom: 10px;}
.bottom-content p { color: #a8a9b0;  font-size: 14px;  margin-bottom: 10px;}

/* Tabs in project description */
.bottom-content ul { list-style: none; line-height: 35px;}
.bottom-content ul li a{
  color: #747AA8;
}

.bottom-content .div-tab-block{
  border-bottom:1px solid #404881;
  padding: 15px 0;
}

.bottom-content .div-tab-block a{ margin-right: 2%; }

.div-tab-block-description img{
  width: 100% !important;
  height: auto !important;
}

.bottom-content .heading{
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
}

.bottom-content .heading img {
  height: 12px;
  margin-right: 10px;
}

.input-field-design{
  background-color: #2e3560 !important;
  border-color: #4f5680 !important;
  height: 40px;
  border-radius: 5px !important;
  outline: none !important;
  color: white !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* Siderbar */
/* image logo */
.bar-heading img {border-radius: 15px; height: 30px; width: 30px;}

/* progress circles */
.progress-div{
  width: 100%; height: 90%; float: left; position: relative;
}

.progress-heading{
  color: #747AA8;
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}



/* New update */




.content { display: flex;  justify-content: space-between;}
.box-row { display: flex;  flex-wrap: wrap;}
.box-cnt {border: 1px solid #747AA8;margin-bottom: 30px;padding: 10px;border-radius: 5px;}
.box-cnt h4 {font-size: 18px;color: #fff;}



.fill-your-solana {
  border-bottom: 1px solid #404881;
  margin: 0 0 20px;
  padding: 25px 0;
}

.fill-your-solana input {
  background: #292f56 !important;
  border: 0;
  border-radius: 20px !important;
  padding: 0 20px;
}

.fill-your-solana .bold {
  margin-bottom: 15px; font-size: 13px; color: white;font-weight: 700;
}

.fill-your-solana .form-control{ height: 35px;}

.fill-your-solana p {
  color: #ffff;
  font-size: 13px;
}

.fill-your-solana svg {
  margin: 0 12px;
}

/* Calender */
.calender-desktop-view{
  background: linear-gradient(111.75deg, #30386D 7.35%, #3B427A 96.73%);
  box-shadow: 0px 4px 100px rgb(33 39 76 / 70%);
  border-radius: 20px;
  padding: 0 0 20px;
  margin: 0 0 30px;  
  display: flex;
  width:100% ;
  justify-content: center;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5px !important;
  color: #747AA8;
  text-align: center;
  font-size: 12px;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0 5px !important;
  border-radius: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer !important;
  font-size: 12px;
  color: #fff; 
}

.DayPicker-Caption div {
  color: #fff;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
}

span.DayPicker-NavButton.DayPicker-NavButton--prev {
  left: 0 !important;
}

span.DayPicker-NavButton.DayPicker-NavButton--next {
  right: 0;
}

.DayPicker-NavBar {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}



  /* ul.submenu li a:hover{
    background:rgba(0,0,0,.3);
  } */

  .vestingHoverDataModal {
    background: linear-gradient(111.75deg, #343B68 7.35%, #262950 96.73%);
    box-shadow: 0px 4px 100px rgba(33, 39, 76, 0.7);
    border-radius: 20px; 
    padding:20px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    /* left: 30%; */
  }
  .vestingHoverDataModal .light-blue-text{color: #747AA8;font-style: normal;font-weight: 400;font-size: 12px;line-height: 18px;}
  .vestingHoverDataModal .white-text{color: #fff;font-style: normal;font-weight: 400;font-size: 12px;line-height: 18px;}
  .vestingHoverDataModal .hash{
    font-style: normal;font-weight: 700;font-size: 12px;line-height: 18px;color: #809AFD;
  }
  .vestingHoverDataModal a{
    text-decoration: none;
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){background: linear-gradient(263.05deg, #EBA724 14.78%, rgba(255, 213, 131, 0.7) 89.13%);
    border-radius: 3px !important;}

  .DayPicker-Day--today{background: linear-gradient(268.58deg, #7C6BDE 9.55%, rgba(167, 154, 246, 0.7) 87.82%);
    border-radius: 3px !important; color:#fff !important;}

  .DayPicker-Day{border-radius: 3px !important; }

  .DayPicker-Day--outside{
    background: transparent !important;
    border: none  !important;
    /* display: none !important; */
  }
  .DayPicker-Day--today{
    background: transparent !important;
    font-weight: 500;
  }

  .myContributionTable{
    width:100%;
  }
  .myContributionTable tr{
    border-bottom:1px solid #404881;
  }
  .myContributionTable th{
    color: #747AA8;
    font-size: 12px;
    padding-bottom: 10px;
  }
  .myContributionTable td{
    color: white;
    font-size: 14px;
    padding: 10px 0;
  }
  .myContributionTable td:first-child {
    word-break: break-word;
    padding-right: 20px;
  }

  /* white button and all ui */
  .white-btn {height:35px;font-size:13px !important;background: transparent !important; border: 0 !important; 
    position: relative; letter-spacing: 1px; padding: 5px 30px; color: #fff;}
    .white-btn:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      padding: 2px;
      background: linear-gradient(103deg, #e3dbff,#607DEA);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
  }
  
  .btn-rw button {
    font-size: 10px !important;
  }
  
  .btn-rw {
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  
  .tab-right button {
    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;font-size: 13px; padding: 0 !important;
    
  }
  
  span.sort-by {
    color: #fff !important;
    margin-right: 5px;
  }
  
  .tab-right button span {
    color: #747AA8;
  }
  
  .tab-right button:hover{background: transparent !important; outline: none !important; box-shadow: none !important;}
  
  .tab-right .dropdown-menu {
    background: #323973;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 12px 0px #292f56eb;
  }
  
  .tab-right .dropdown-menu a:hover{color:#fff; background:transparent !important;}
  
  .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color:transparent !important;
    border-color:transparent !important; box-shadow: none !important;
  }

  button#dropdown-basic:focus {
    background: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .stick-to-top{
    position: fixed;
    top: 0;
    z-index: 8;
    border-bottom:2px solid rgb(108 126 229);
  }
  @media screen and (min-width: 768px) {
    .stick-to-top{
      border-bottom: none;
      width: 17%;
    }
  }

  @media screen and (min-width: 1200px) {
    .stick-to-top{
      width: 10%;
    }
  }

  @media screen and (min-width: 1450px) {
    .stick-to-top{
      width: 180px;
    }
  }
