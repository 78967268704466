.maine-content {margin-top: 20px;}

.btn-main-red:hover { background: linear-gradient(91.53deg, #eb3333 2.89%, #f37373 97.48%);}
.btn-main-yellow:hover { background: linear-gradient(91.53deg, #c5c753 2.89%, #f1ef63 97.48%);}
.btn-main-gray:hover { background: linear-gradient(91.53deg, #757575 2.89%, #c4c4c4 97.48%);}

/* Admin page settings */
.page-heading{font-size: 34px; color: #fff;  margin-bottom: 20px;}

.input-design { 
  background-color: #2e3560 !important;
  outline: none;
  border-color: #3d446e !important;
  height: 45px;
  border-radius: 5px !important; outline: none !important;
  color: white !important;
}

table{
  word-wrap: break-word;
}

.map-label { color: #fff;  border: 1px solid #fff;  padding: 5px 10px;  border-radius: 2px;   width: 100%;   display: flex;   align-items: center;
    justify-content: space-between; margin: 0 0 10px;}

/* .DraftEditor-root{
  color: white;
  border: 1px solid white;
  padding: 5px;
} */
.wrapper-class {
  /* padding: 1rem;
  border: 1px solid #ccc; */
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}