


.button-profile button {
  height: 35px;
  color: #fff;
  letter-spacing: 1px;
  border: 1px solid #809AFD;
  padding: 5px 30px;
  font-size: 12px;
  background: transparent;
  border-radius: 15px;
}

.content { display: flex;  justify-content: space-between;}

.profile-row { display: flex; align-items: center;}
.profile-pic {width: 90px;height: 90px;background: #4D55924D;border-radius: 15px;display: flex; align-items: center; justify-content: center;}
.profile-pic p {
  color: #fff;
  opacity: 0.3;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
}

.profile-cnt {margin-left: 20px;display: flex; align-items: center; flex-wrap: wrap; width: calc(100% - 180px);}
.profile-cnt h3 {font-size: 15px;  color: #fff;}
.more-user {border-top: 1px solid #404881; margin: 20px -20px 0; padding: 20px 20px 0;}

.box-row { display: flex;  flex-wrap: wrap;}
.box-cnt {border: 1px solid #809AFD;margin-bottom: 30px; padding: 10px; border-radius:20px;}

.unlock-btn {display: flex; justify-content: space-between; align-items: center; margin-bottom:30px;}
.unlock-btn button {
  font-size: 12px;
  background: transparent !important;
  border: 2px solid #58619d !important;
  border-radius: 15px;
  height: 35px;
  color: #fff;
  padding: 0 15px;
}

.more-user p {font-size: 12px; color: #747AA8;  text-align: center;}
.popup-header h2 svg {width: 20px;margin-right: 10px;}
.no-access-popup .row-box {border-top: 1px solid #404881;padding: 12px 0; justify-content: space-between;}
.no-access-popup .custom_radio {border-top: 1px solid #404881; padding: 25px 0 20px; font-size: 13px; color: #fff; justify-content: space-between;}
.popup-body.no-access-popup {padding: 30px 80px 30px;}

ul#menu > li a:hover {color: #fff;}



.profile-popup {
  font-size: 12px !important;
  background: transparent !important;
  border: 2px solid #58619d !important;
  border-radius: 15px !important;
  height: 35px;
  color: #fff;
  padding: 0 15px;margin-bottom:30px;
}